import type { Span, SpanContext, Tracer } from 'dd-trace';

type Nullable<T> = T | null;

/**
 * Private access for the root span
 * https://github.com/DataDog/dd-trace-js/issues/725
 */
type PrivateSpanContext = SpanContext & {
  _trace: {
    started: Span[];
  };
};

/**
 * We import the tracer initially in server-preload.js
 * importing it from `dd-trace` here causes build errors
 * so we just access the global instance
 *
 * Casting global is necessary to avoid needing to patch the global object in every library
 */
export const tracer = (global as unknown as { _ddtrace: Tracer })
  ._ddtrace as Tracer;

const getCurrentSpan = (): Nullable<Span> => tracer?.scope().active() ?? null;

/**
 * Only errors on the root span get added to the Error Tracker
 */
const getRootSpan = (): Nullable<Span> => {
  const span = getCurrentSpan();
  const spanContext = span?.context() as PrivateSpanContext;
  return spanContext?._trace.started[0] || span;
};

/**
 * Add tags to a span to have more context about how and why it was running.
 * If added to the root span, tags are searchable and filterable.
 *
 * @param tags An object with the tags to add to the span
 * @param span An optional span object to add the tags to. If none provided, the current span will be used.
 */
export const addTags = (
  tags: Record<string, unknown>,
  span?: Nullable<Span>
): void => {
  const currentSpan = span || getRootSpan();

  if (!currentSpan) {
    return;
  }

  currentSpan.addTags(tags);
};

/**
 * Datadog will mark spans as errors if they throw an error.
 * This function will allow other spans to show up as errors as well.
 *
 * @param error An Error object.
 * @param span An optional span object to add the tags to. If none provided, the current span will be used.
 */
export const markAsError = (error: Error, span?: Span): void => {
  addTags(
    {
      errorMessage: error.message,
      'error.type': error.name,
      'error.msg': error.message,
      'error.stack': error.stack,
    },
    span
  );
};
